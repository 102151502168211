import { fetchWrapper } from "../helpers/fetchWrapper";

const createPayment = async (body) => {
  try {
    return await fetchWrapper.post(`/payment`, body);
  } catch (e) {
    throw new Error(e);
  }
};
const createPaymentAlternative2 = async (body) => {
  try {
    return await fetchWrapper.post(`/payment/alternative2`, body);
  } catch (e) {
    throw new Error(e);
  }
};

export const paymentService = {
  createPayment,
  createPaymentAlternative2,
};
