import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { filesServices } from "../../services/filesServices";

export const getUserUploadedFiles = createAsyncThunk(
  "getUserUploadedFiles",
  async (userId) => {
    try {
      return await filesServices.getUserUploadedFiles(userId);
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const uploadedFilesSlice = createSlice({
  name: "files",
  initialState: {
    files: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserUploadedFiles.fulfilled, (state, action) => {
      state.files = action.payload;
    });
  },
});

export default uploadedFilesSlice.reducer;
