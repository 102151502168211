import React from "react";
import GroupIcon from "@mui/icons-material/Group";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import "./index.scss";
import { useSelector } from "react-redux";
import ColumnGroupingTableTransactions from "./components/transactionsAdminDashboard";
import ColumnGroupingTableWithdraws from "./components/withdrawAdminDashboard";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Dashboard = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.defaults.font.weight = "bold";
  ChartJS.defaults.plugins.legend.labels.font = 18;
  ChartJS.defaults.plugins.legend.labels.color = "white";
  const totalApprovedWithdrawals = useSelector(
    (state) => state.withdrawHistory.withdrawHistory?.totalApprovedWithdrawals
  );
  const transactionsTotalAmount = useSelector(
    (state) =>
      state.transactions.transactions?.totalTransactionWithoutLiquidityAdvance
  );

  const thisMonthTransactions = useSelector(
    (state) => state.transactions.transactions?.thisMonthTransactions
  );

  const usersCount = useSelector(
    (state) => state.transactions.transactions?.usersCount
  );

  return (
    <div className="admin-dashboard">
      <h2
        style={{
          color: "rgb(225, 230, 225)",
          textAlign: "center",
          fontSize: "1.8rem",
          padding: "10px",
        }}
      >
        {!!usersCount ? "Dashboard" : <Skeleton />}
      </h2>
      {!!usersCount ? (
        <div className="admin-dashboard-cards-container">
          <div className="admin-dashboard-card">
            <GroupIcon fontSize={"large"}></GroupIcon>
            <div>
              <h3>{usersCount}</h3>
              <span>{"Registered Users"}</span>
            </div>
          </div>
          <div className="admin-dashboard-card">
            <AttachMoneyIcon fontSize={"large"} />
            <div>
              <h3>{transactionsTotalAmount?.toFixed(2)}</h3>
              <span>{"Transactions"}</span>
            </div>
          </div>
          <div className="admin-dashboard-card">
            <AttachMoneyIcon fontSize={"large"} />
            <div>
              <h3>{totalApprovedWithdrawals?.toFixed(2)}</h3>
              <span>{"Total Withdrawals"}</span>
            </div>
          </div>
          <div className="admin-dashboard-card">
            <AttachMoneyIcon fontSize={"large"} />
            <div>
              <h3>{thisMonthTransactions?.toFixed(2)}</h3>
              <span>This Month Transactions</span>
            </div>
          </div>
        </div>
      ) : (
        <Skeleton height={80} />
      )}

      <div className="admin-dashboard-tables">
        <ColumnGroupingTableTransactions />
        <ColumnGroupingTableWithdraws />
      </div>
      <div style={{ display: "flex" }}></div>
    </div>
  );
};

export default Dashboard;
