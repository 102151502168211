import { Box } from "@material-ui/core";
import "./index.scss";
import Zoom from "@mui/material/Zoom";
import Reveal, { Slide } from "react-awesome-reveal";

const InfoPage = () => {
  return (
    <>
      {/* <h1 className="info-page-header">info</h1> */}

      <Reveal duration={2000}>
        <Box
          style={{
            marginTop: window.location.href.includes("info") ? "3rem" : "0",
          }}
          className="info-page-container"
        >
          <Box className="info-page-textBox">
            <h3 className="info-page-h3">Secure Trading Platform</h3>
            <Box className="info-page-span">
              <span>
                We have trading terminals native for the Mac, Windows, Linux,
                Android, iOS, Blackberry, Web Browser, and HTML5 Cloud.
              </span>
              <br></br>
              <span>
                Our platform has more than 10 layers of security. Some of which
                are SSL, JWT, HASHING, ENCRYPTION,PROXIES, LINUX CLOUD.
              </span>
            </Box>
          </Box>

          <Box className="info-page-textBox2">
            <h3 className="info-page-h3">Security and Compliance</h3>
            <Box className="info-page-span">
              <span>
                We prioritize the security of your transactions and customer
                data. Our solutions adhere to the highest industry standards and
                comply with PCI-DSS regulations, ensuring your business and
                customers are protected against fraud and data breaches
              </span>
            </Box>
          </Box>
        </Box>
      </Reveal>
    </>
  );
};

export default InfoPage;
