import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";

import "./index.scss";
import { userServices } from "../../services/userServices";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function ForgottenPasswordPage() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [newRepeatedPassword, setNewRepeatedPassword] = useState("");
  const [temporaryPassword, setTemporaryPassword] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("address");
  const handleForgotPasswordFlowReset = async (e) => {
    e.preventDefault();
    await userServices
      .postChangeForgotPassword({
        temporaryPassword,
        newRepeatedPassword,
        email,
      })
      .then((res) => {
        setNewPassword("");
        setNewRepeatedPassword("");
        setTemporaryPassword("");
        toast.success(`${res.data}`, {
          position: "top-center",
          hideProgressBar: false,
          autoClose: true,
          closeOnClick: true,
          pauseOnHover: true,
          toastId: 123,
        });
      })
      .catch((error) => {
        toast.error(`${error.message}`, {
          position: "top-center",
          hideProgressBar: false,
          autoClose: true,
          closeOnClick: true,
          pauseOnHover: true,
          toastId: 143,
        });
      });
  };
  return (
    <>
      <div>
        <div className="forgotten-container">
          <div className="forgotten-panel">
            <h2 style={{ color: "#1eccbd" }}>
              Please change your password bellow!
            </h2>
            <form action="" method="post" className="forgotten-form">
              <div className="forgotten-panel-group">
                <input
                  className="forgotten-input"
                  type="password"
                  required
                  aria-label="temporary-password"
                  placeholder="Temporary password"
                  onChange={(e) => setTemporaryPassword(e.target.value)}
                />
                <input
                  className="forgotten-input"
                  type="password"
                  required
                  aria-label="new-password"
                  placeholder="New password"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <input
                  className="forgotten-input"
                  type="password"
                  required
                  placeholder="Repeat new password"
                  onChange={(e) => setNewRepeatedPassword(e.target.value)}
                />
                <span className="forgotten-border"></span>
              </div>

              <button
                type="button"
                onClick={(e) => handleForgotPasswordFlowReset(e)}
              >
                Save password
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default ForgottenPasswordPage;
