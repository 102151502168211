import { fetchWrapper } from "../helpers/fetchWrapper";

const getTransactions = async (payload) => {
  try {
    const searchQuery = new URLSearchParams(payload).toString();
    return await fetchWrapper.get(`/transactions?${searchQuery}`);
  } catch (e) {
    throw new Error(e);
  }
};

const getUserTransactions = async (id) => {
  try {
    return await fetchWrapper.get(`/transactions/${id}`);
  } catch (e) {
    throw new Error(e);
  }
};

const createTransaction = async (body) => {
  try {
    return await fetchWrapper.post(`/transactions/create`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const updateTransaction = async (id, body) => {
  try {
    return await fetchWrapper.put(`/transactions/update/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const deleteTransaction = async (id) => {
  try {
    return await fetchWrapper.delete(`/transactions/delete/${id}`);
  } catch (e) {
    throw new Error(e);
  }
};
export const transactionService = {
  getTransactions,
  getUserTransactions,
  createTransaction,
  updateTransaction,
  deleteTransaction,
};
