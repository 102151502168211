import { fetchWrapper } from "../helpers/fetchWrapper";

const uploadFile = async (body) => {
  try {
    return await fetchWrapper.post(`/files/create`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const getUserUploadedFiles = async (userId) => {
  try {
    return await fetchWrapper.get(`/files/${userId}`);
  } catch (e) {
    throw new Error(e);
  }
};

export const filesServices = {
  uploadFile,
  getUserUploadedFiles,
};
