import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginHistoryServices } from "../../services/loginHistoryServices";

export const getUserLoginHistory = createAsyncThunk(
  "getUserLoginHistory",
  async (userId) => {
    try {
      return await loginHistoryServices.getUserLoginHistory(userId);
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getFullLoginHistory = createAsyncThunk(
  "getFullLoginHistory",
  async () => {
    try {
      return await loginHistoryServices.getFullLoginHistory();
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const loginHistorySlice = createSlice({
  name: "loginHistory",
  initialState: {
    loginHistory: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserLoginHistory.fulfilled, (state, action) => {
        state.loginHistory = action.payload;
      })
      .addCase(getFullLoginHistory.fulfilled, (state, action) => {
        state.loginHistory = action.payload;
      });
  },
});

export default loginHistorySlice.reducer;
