import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUserWithdrawHistory } from "../../../redux/withdrawHistory/withdrawHistorySlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  // hide last border
  "td,th": {
    border: 0,
  },
}));

export default function WithDrawPageTable() {
  const dispatch = useDispatch();
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const withdrawHistory = useSelector(
    (state) => state.user.user.foundUser?.withdrawHistories
  );
  React.useEffect(() => {
    dispatch(getUserWithdrawHistory(user.id));
  }, []);
  return (
    <div className="withdraw-table-root-container">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell className="withdraw-table-cell">
                CREATED
              </StyledTableCell>
              <StyledTableCell className="withdraw-table-cell" align="center">
                DESCRIPTION
              </StyledTableCell>
              <StyledTableCell className="withdraw-table-cell" align="center">
                STATUS
              </StyledTableCell>
              <StyledTableCell className="withdraw-table-cell" align="center">
                AMOUNT&nbsp;($)
              </StyledTableCell>
              <StyledTableCell className="withdraw-table-cell" align="center">
                BEFORE&nbsp;($)
              </StyledTableCell>
              <StyledTableCell className="withdraw-table-cell" align="center">
                AFTER&nbsp;($)
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {withdrawHistory?.map((withdraw) => (
              <StyledTableRow
                key={withdraw.id}
                style={{
                  background: "#07232E",
                  borderBottom: "1px solid #1d4352",
                }}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  style={{ color: "rgb(183 189 192)" }}
                >
                  {new Date(+withdraw?.createdAt).toUTCString()}
                </StyledTableCell>
                <StyledTableCell
                  className="withdraw-history-table-cell"
                  align="center"
                >
                  {withdraw?.description}
                </StyledTableCell>
                <StyledTableCell
                  className="withdraw-history-table-cell"
                  align="center"
                >
                  {withdraw?.status}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: "white",
                    fontWeight: "bolder",
                    color: "#18d818",
                  }}
                  align="center"
                >
                  {Number(withdraw?.amount).toFixed(2)}
                </StyledTableCell>
                <StyledTableCell
                  className="withdraw-history-table-cell"
                  align="center"
                >
                  {Number(withdraw?.beforeWithdraw).toFixed(2)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: "white",
                    fontWeight: "bolder",
                    color: "#18d818",
                  }}
                  align="center"
                >
                  {Number(withdraw?.afterWithdraw).toFixed(2)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
