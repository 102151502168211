import { Box, Button } from "@material-ui/core";
import { useNavigate } from "react-router";

import "./index.scss";
import { Slide } from "react-awesome-reveal";

const ToolsPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box style={{ marginBottom: "3rem" }} className="tools-page-container">
        <Slide duration={2000}>
          <Box className="tools-types-container">
            <Box className="tools-types-risk-img">
              <img src="icon-zero-commission.svg" alt="zero commission icon" />
            </Box>
            <Box className="tools-types-wrapper">
              <h1 className="tools-types-headers">Close to 0% Commission</h1>
              <span className="tools-types-spans">
                Welcome to the world's markets. Explore endless trading
                opportunities.<br></br>
                In VRC Markets<br></br>
                without paying any commission.
              </span>
              <br></br>
              <br></br>
              <span className="tools-types-spans">
                Zero commission is only available to clients of VRC Markets,{" "}
                <br></br>
                and does not apply to short or leveraged stock trades. Zero
                commission <br></br>
                means that no broker fee has been charged when opening or
                closing the position.
                <br></br> Other fees may apply.
              </span>
              <br></br>
              <br></br>
              <div className="button-wrap">
                <Button
                  variant="outlined"
                  color="inherit"
                  className="tools-buttons"
                  onClick={() => navigate("/sign-up")}
                >
                  Join Now
                </Button>
              </div>
            </Box>
          </Box>
        </Slide>
        <Slide duration={2000} direction="right">
          <Box className="tools-types-container-2">
            <Box style={{ marginTop: "30px" }} className="tools-types-risk-img">
              <img src="icon-risk-management.svg" alt="risk management icon" />
            </Box>
            <Box className="tools-types-wrapper">
              <h1 className="tools-types-headers">Risk Management</h1>
              <span className="tools-types-spans">
                We have developed a unique combination of technical
                <br></br> and fundamental analytical support, individual account
                types, <br></br>instant executions and the best available
                specialized software assistance.
              </span>
              <br></br>
              <br></br>
              <span className="tools-types-spans">
                With features such as Stop Loss/Take Profit, you can manage
                <br></br> both your losses and profits at predetermined levels
                by you.
              </span>
              <br></br>
              <br></br>
              <span className="tools-types-spans">
                Risk is fundamental to the investment process. Always use{" "}
                <br></br>
                Risk Management Tools along with expertise from your Financial
                Analyst.
              </span>
              <br></br>
              <br></br>

              <div className="button-wrap">
                <Button
                  color="inherit"
                  variant="outlined"
                  className="tools-buttons"
                  onClick={() => navigate("/sign-up")}
                >
                  Join Now
                </Button>
              </div>
            </Box>
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export default ToolsPage;
