import "./index.scss";
export const CancelledDeposit = () => {
  return (
    <div className="box">
      <div className="error alert">
        <div className="alert-body">Transaction Unsuccessful</div>
      </div>
    </div>
  );
};
