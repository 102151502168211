import { useSelector } from "react-redux";
import OpenedOrdersTable from "../../../../../tradePage/components/Orders/components/openedOrders";

const CurrentOpenedOrder = ({ setOpenModal, user, fetchUsersPayload }) => {
  const orders = useSelector((state) => state.orders.orders);
  const currentOpenedOrder = orders?.filter(
    (order) => !order?.closedAt && order?.userId === user?.id
  )[0]?.id;
  return (
    <>
      {!!currentOpenedOrder ? (
        <OpenedOrdersTable
          user={user}
          fetchUsersPayload={fetchUsersPayload}
          setOpenModal={setOpenModal}
        />
      ) : (
        <h3 style={{ color: "red" }}>
          Currently no opened orders for this user...
        </h3>
      )}
    </>
  );
};
export default CurrentOpenedOrder;
