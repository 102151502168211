import { useDispatch, useSelector } from "react-redux";
import { getUserBalance } from "../../../../../redux/users/userSlice";
import { userServices } from "../../../../../services/userServices";
import React from "react";
import "./index.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TextareaAutosize } from "@mui/material";
import { getAllRegisteredUsers } from "../../../../../redux/allRegisteredUsers/allRegisteredUsersSlice";

const AddNewCommentModal = ({
  addNewCommentModal,
  setAddNewCommentModal,
  page,
  rowsPerPage,
  filterOptions,
  fetchUsersPayload,
}) => {
  const [leadNotes, setLeadNotes] = React.useState({
    notes: "",
  });
  const reduxUser = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );

  const loggedInUserRole = reduxUser?.role;

  const loggedInUserName =
    reduxUser?.role !== "admin" &&
    reduxUser?.role !== "head_of_conversion" &&
    reduxUser?.role !== "crm_manager"
      ? reduxUser?.firstName + " " + reduxUser?.lastName
      : "";
  const dispatch = useDispatch();
  const handleCreateNote = (e) => {
    const value = e.target.value;
    setLeadNotes({
      ...leadNotes,
      [e.target.name]: value,
    });
  };
  const handleSaveNewComment = () => {
    userServices
      .addNoteByLeadId(addNewCommentModal.id, {
        notes: leadNotes,
      })
      .then(() => {
        dispatch(getAllRegisteredUsers(fetchUsersPayload));
        setAddNewCommentModal(null);
        setLeadNotes({
          note: "",
        });
      });
  };
  return (
    <div className="comment-user-wrap">
      <div className="comment-user-modal">
        <div className="comment-user-modal-info">
          <div className="comment-user-top">
            <h2
              style={{
                color: "white",
                padding: "10px",
                fontSize: "1.4rem",
                textAlign: "center",
              }}
            >
              Leave comment to:{" "}
              <span style={{ color: "green" }}>
                {addNewCommentModal.firstName +
                  " " +
                  addNewCommentModal.lastName}
              </span>
            </h2>
            <CloseIcon
              style={{ cursor: "pointer", color: "white" }}
              onClick={() => {
                // dispatch(getUserBalance(user.id));
                setAddNewCommentModal(null);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextareaAutosize
              className="edit-user-textField"
              label="Comment"
              variant="outlined"
              name="note"
              placeholder="Comment"
              maxRows={10}
              minRows={6}
              style={{
                height: "96px",
                minWidth: 550,
                maxWidth: 800,
                fontWeight: "bold",
                color: "white",
                fontFamily: "Montserrat",
                backgroundColor: "#1F2A40",
                marginBottom: "2rem",
              }}
              fullWidth
              onChange={(e) => handleCreateNote(e)}
              value={leadNotes.note}
              InputLabelProps={{
                style: { color: "white", fontWeight: "bold" },
              }}
            />
            {!!leadNotes["note"] && (
              <Button
                style={{
                  width: "120px",
                  height: "50px",
                  background: "yellow",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                  alignSelf: "center",
                  marginBottom: "1rem",
                }}
                variant="outlined"
                onClick={handleSaveNewComment}
              >
                Save comment
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewCommentModal;
