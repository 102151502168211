import { Box, Grid } from "@material-ui/core";
import React from "react";

import "./index.scss";
import { Zoom } from "react-awesome-reveal";
function PaymentFooter() {
  return (
    <Box className="payment-footer-container">
      <ul className="payment-footer-ul">
        <li>
          <Zoom>
            <img className="paymentFooter-img" src="/bank_wire.png"></img>
          </Zoom>
        </li>
        <li>
          <Zoom>
            <img className="paymentFooter-img" src="/bitcoin.png"></img>
          </Zoom>
        </li>
        <li>
          <Zoom>
            <img className="paymentFooter-img" src="visa.png"></img>
          </Zoom>
        </li>
        <li>
          <Zoom>
            <img className="paymentFooter-img" src="mastercard.png"></img>
          </Zoom>
        </li>
        <li>
          <Zoom>
            <img className="paymentFooter-img" src="aps-ssl.png"></img>
          </Zoom>
        </li>
      </ul>
    </Box>
  );
}

export default PaymentFooter;
