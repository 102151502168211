import React from "react";
import "./index.scss";
import ColumnGroupingTableAffiliate from "./components/affiliateTable";
import CreateAffiliate from "./components/createAffiliate";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

const AdminAffiliates = () => {
  const affiliates = useSelector((state) => state.affiliate.affiliates);
  return (
    <div className="admin-affiliate">
      <div>
        <h2>{!!affiliates ? "Manage affiliates" : <Skeleton />}</h2>
      </div>
      <div className="admin-affiliate-table">
        <CreateAffiliate />
        {/* <ColumnGroupingTableAffiliate /> */}
      </div>
    </div>
  );
};

export default AdminAffiliates;
