import React from "react";
import { useSelector } from "react-redux";
import { AdvancedChart } from "react-tradingview-embed";

const EmbededChart = () => {
  const state = useSelector((state) => state.cryptoMarketDataReducer);
  const mobileWidth = window.innerWidth;

  return (
    <div id="tvchart">
      <AdvancedChart
        widgetProps={{
          timezone: "Etc/UTC",
          theme: "dark",
          style: "9",
          width:
            mobileWidth <= 658 && mobileWidth >= 500
              ? 550
              : mobileWidth < 500 && mobileWidth >= 400
              ? 450
              : mobileWidth < 400
              ? 350
              : 900,
          height: mobileWidth < 657 ? 350 : 540,
          locale: "en",
          toolbar_bg: "rgba(102,102,102,0.4)",
          save_image: false,
          enable_publishing: false,
          allow_symbol_change: true,
          symbol: state.symbol,
        }}
        widgetPropsAny={{ newProp: true }}
      />
    </div>
  );
};

export default React.memo(EmbededChart);
