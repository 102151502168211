import { Box } from "@material-ui/core";
import React from "react";
import "./index.scss";

function SecurityPolicyPage() {
  return (
    <Box className="sec-policy-page-container">
      <Box>
        <h1 className="sec-policy-page-header">Security Policy</h1>
      </Box>
      <Box className="sec-policy-page-textBox">
        <span className="sec-policy-page-span">
          VRC Markets Security Policy SECURITY AND DISCLOSURE At VRC Markets we
          regard security for our clients as a top priority concern. It is our
          responsibility to safeguard your information, and we wish to stress
          that your personal data and trading history will always be treated
          with the utmost respect and care. The conditions of this security
          policy apply equally to all applicants, former- as well as current
          clients. PERSONAL DATA Upon applying for a VRC Markets account, we
          will ask you for some information. This will allow us to determine
          your financial needs, perform account operations, send updates
          regarding suitable services, and complete customer service requests.
          While your account is active, we may also ask you to update your
          records from time to time. In order to provide the best quality of
          support, we may need to track any or all of the following: Personal
          records, such your first and last name, place of residence, date of
          birth, government identification numbers, landline and mobile phone
          numbers, employer and position, household assets, and annual salary
          Information about your trading history, including your account
          balance, customer service records, and trading activity Copies of
          information required to authenticate your identity, such as a valid
          government issues ID, or results from public records searches or from
          third party information brokers. VRC Markets works with local
          jurisdictions to perform our due diligence in preventing criminal
          activities such as money laundering, and identity theft USE OF COOKIES
          VRC Markets creates small files known as ‘cookies’ on your computer.
          Cookies allow us to track how visitors reached our website. VRC
          Markets and/or our affiliated service providers may also make use of
          cookies to see how clients use our services when visiting the website.
          All information gathered through the use of these cookies is stripped
          of identifiers and then aggregated so that each user is anonymous.
          SECURITY OF INFORMATION VRC Markets uses Secure Socket Layer (SSL)
          technology. This allows us to encrypt your information and thereby
          protecting the data you send to us. SSL technology keeps your data
          safe while it is being transmitted to us. We use the most up-to-date
          standards to keep your information from being intercepted. Other
          safeguards that we employ include firewalls, password procedures, and
          access notification systems which warn of intrusion attempts.
          AFFILIATE INFORMATION ACCESS Our affiliates may be either owned or
          controlled by us or may have ownership or a controlling interest in
          our company. Our affiliates agree to follow the same security policies
          that are listed above. Use of the VRC Markets trading platform and
          website constitutes consent for us to share any or all of the
          information covered under this security policy with our affiliates for
          various business uses, including marketing, customer support, and to
          aid in the commercial activity of VRC Markets. THIRD PARTY DISCLOSURES
          VRC Markets will not provide third parties with your personal
          information except under the circumstances described within this
          security policy. If necessary, we may need to give third parties
          access to your information for market research, marketing, support
          services, or transaction processing. We also may need to provide
          records to non-affiliated companies who provide services to VRC
          Markets in a professional capacity, including legal and financial
          services. Any third parties who are given access to personal
          information must agree to keep this information confidential and to
          use the data provided only in the course of performing agreed upon
          services for VRC Markets. VRC Markets may release information to third
          parties if it has been aggregated in such a way as to ensure
          anonymity. Additionally, when you give express consent to disclose
          your personal information to third parties, we are free to act on your
          instructions. Under no circumstances will VRC Markets sell your
          personal information. DISCLOSURES TO REGULATORY AUTHORITIES VRC
          Markets may sometimes be called upon to disclose your personal
          information in order to cooperate with requests from legal authorities
          or regulatory agencies. This includes responses to subpoenas and other
          formal requests, or as part of a legal action to protect our rights.
          We will not make use of any personal information for other purposes,
          except as described in this policy without seeking your permission.
          OPTING OUT It is not mandatory to supply VRC Markets with the types of
          personal information listed in this policy. However, we may not be
          able to provide you with services without the availability of this
          information. We ask that you help keep our records accurate by
          providing accurate information, and by immediately updating our
          records when there are any changes. To request VRC Markets to refrain
          from giving out personal information to any affiliates or third
          parties Please contact us via email at: support@VRC Markets.com Please
          be advised, that after receipt of this request, we will close your
          account and return any funds, as we will not be able to provide
          services under these circumstances.
        </span>
      </Box>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Box>
  );
}

export default SecurityPolicyPage;
