import "./index.scss";

export const SuccessfulDeposit = () => {
  return (
    <div className="box">
      <div className="success alert">
        <div className="alert-body">Transaction Successful</div>
      </div>
    </div>
  );
};
