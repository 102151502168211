import React, { useEffect } from "react";
import ColumnGroupingTable from "./users-table";
import "./users.scss";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

const Users = () => {
  let isSidebarExtended;
  useEffect(() => {
    isSidebarExtended = window.localStorage.getItem("sidebar");
  }, []);
  const users = useSelector((state) => state.users.users);

  return (
    <div className="admin-users">
      <div>
        <h2
          style={{
            color: "rgb(225, 230, 225)",
            textAlign: "center",
            fontSize: "1.8rem",
            padding: "10px",
            // background: '#121C31',
          }}
        >
          {!!users ? "Users Management" : <Skeleton />}
        </h2>
      </div>
      <div className="admin-users-table">
        <ColumnGroupingTable />
      </div>
    </div>
  );
};

export default Users;
