import React, { useEffect, useState } from "react";
import "./index.scss";
import SubNavbar from "../../components/SubNavbar";
import Input from "@mui/material/Input";
// import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { withdrawHistoryService } from "../../services/withdrawHistoryServices";
import WithDrawPageTable from "./components/withdraw-page-table";
import { getUser } from "../../redux/users/userSlice";
import { getUserWithdrawHistory } from "../../redux/withdrawHistory/withdrawHistorySlice";
import { userServices } from "../../services/userServices";
function WithDrawPage() {
  const styles = (theme) => ({
    textField: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: 0,
      marginTop: 0,
      fontWeight: 500,
    },
    input: {
      color: "white",
    },
  });
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const withdrawHistory = useSelector(
    (state) =>
      state.withdrawHistory && state.withdrawHistory.withdrawHistory?.withdraw
  );
  const dispatch = useDispatch();
  const allWithdrawalsWithStatusPending = withdrawHistory
    ?.filter((withdraw) => withdraw.status === "pending")
    .map((withdraw) => Number(withdraw.amount));
  const amountOfPendingWithdraws = allWithdrawalsWithStatusPending?.reduce(
    (withdraw, acc) => (acc += withdraw),
    0
  );
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [doYouWantToWithdraw, setDoYouWantToWithdraw] = useState(false);
  function handleAmountChange(e) {
    setAmount(e.target.value);
  }
  function handleDescriptionChange(e) {
    setDescription(e.target.value);
  }

  const handleWithdraw = (e) => {
    withdrawHistoryService
      .createWithdraw({
        createdAt: +new Date(),
        description: description,
        amount: amount,
        beforeWithdraw: user.ballance,
        afterWithdraw: user.ballance - amount,
        userId: user.id,
        status: "pending",
      })
      .then(() => dispatch(getUser(user?.id)))
      .then(() => dispatch(getUserWithdrawHistory(user?.id)))
      .then(() => setDoYouWantToWithdraw(false));
  };

  return (
    <div className="with-draw-page-root-container-paren-root">
      <SubNavbar />
      <div className="with-draw-page-root-container-parent">
        <div className="with-draw-page-root-container">
          <div className="with-draw-page-container-header">
            <h3>WITHDRAW REQUEST</h3>
          </div>

          <div className="with-draw-container">
            <div className="with-draw-req">
              <div className="with-draw-page-payment-method-header">
                <h5>Amount</h5>
              </div>
              <div className="with-draw-page-request-wrapper">
                <FormControl fullWidth variant="standard">
                  <Input
                    style={{ color: "white" }}
                    inputProps={{
                      style: { backgroundColor: "#0a2f3d" },
                    }}
                    type="number"
                    id="standard-adornment-amount"
                    onChange={handleAmountChange}
                    startAdornment={
                      <InputAdornment
                        position="start"
                        className="before-component"
                      >
                        {/* {user?.currencySymbol} */}€
                      </InputAdornment>
                    }
                  />
                  <span>
                    Available Funds: €{Number(user.ballance).toFixed(2)}
                  </span>
                  <span>Comments</span>
                  <textarea
                    onChange={handleDescriptionChange}
                    style={{ backgroundColor: "#0a2f3d" }}
                  />
                  <Button
                    variant="contained"
                    color="success"
                    className="with-draw-page-payment-method-button"
                    onClick={() => setDoYouWantToWithdraw(true)}
                    disabled={
                      Number(user.ballance) < amount ||
                      amountOfPendingWithdraws > Number(user.ballance) ||
                      description.trim().length === 0 ||
                      !amount
                    }
                  >
                    Withdraw Request
                  </Button>
                </FormControl>
              </div>
              {doYouWantToWithdraw && (
                <div
                  className="areYouSureModal"
                  style={{
                    width: "35%",
                    height: "40%",
                    inset: 0,
                    margin: "20% auto",
                  }}
                >
                  <h2 style={{ margin: "1rem auto" }}>
                    Withdrawal Confirmation
                  </h2>
                  <h3 style={{ fontSize: "0.9rem", color: "red" }}>
                    Are you sure you would like to withdraw the amount of:
                  </h3>
                  <p style={{ color: "gray" }}>Amount: {amount}</p>
                  <p style={{ color: "gray" }}>Description: {description}</p>
                  <div className="areYouSureModalBtnContainer">
                    <Button
                      variant="contained"
                      color="success"
                      style={{
                        height: "50px",
                        width: "100px",
                        marginTop: "10%",
                      }}
                      onClick={handleWithdraw}
                    >
                      CONFIRM
                    </Button>
                    <Button
                      style={{
                        height: "50px",
                        width: "100px",
                        marginTop: "10%",
                      }}
                      variant="contained"
                      color="error"
                      onClick={() => setDoYouWantToWithdraw(false)}
                    >
                      CANCEL
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <div className="with-draw-history">
              <h5 className="with-draw-page-withdraw-header">
                Withdraw History
              </h5>
              <WithDrawPageTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WithDrawPage;
