import { fetchWrapper } from "../helpers/fetchWrapper";

const getFullLoginHistory = async () => {
  try {
    return await fetchWrapper.get(`/loginHistory`);
  } catch (e) {
    throw new Error(e);
  }
};

const getUserLoginHistory = async (id) => {
  try {
    return await fetchWrapper.get(`/loginHistory/${id}`);
  } catch (e) {
    throw new Error(e);
  }
};

export const loginHistoryServices = {
  getFullLoginHistory,
  getUserLoginHistory,
};
