import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { transactionService } from "../../services/transactionServices";

export const getUserTransactions = createAsyncThunk(
  "getUserTransactions",
  async (userId) => {
    try {
      return await transactionService.getUserTransactions(userId);
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getAllTransactions = createAsyncThunk(
  "getAllTransactions",
  async (payload) => {
    try {
      return await transactionService.getTransactions(payload);
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const transactionsSlice = createSlice({
  name: "transactions",
  initialState: {
    transactions: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload;
      })
      .addCase(getAllTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload;
      });
  },
});

export default transactionsSlice.reducer;
