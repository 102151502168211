import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { withdrawHistoryService } from "../../services/withdrawHistoryServices";

export const getUserWithdrawHistory = createAsyncThunk(
  "getUserWithdrawHistory",
  async (userId) => {
    try {
      return await withdrawHistoryService.getUserWithdrawHistory(userId);
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getAllWithdrawHistory = createAsyncThunk(
  "getAllWithdrawHistory",
  async (payload) => {
    try {
      return await withdrawHistoryService.getWithdrawHistory(payload);
    } catch (e) {
      throw new Error(e);
    }
  }
);
export const withdrawHistorySlice = createSlice({
  name: "withdrawHistory",
  initialState: {
    withdrawHistory: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserWithdrawHistory.fulfilled, (state, action) => {
        state.withdrawHistory = action.payload;
      })
      .addCase(getAllWithdrawHistory.fulfilled, (state, action) => {
        state.withdrawHistory = action.payload;
      });
  },
});

export default withdrawHistorySlice.reducer;
